import React from "react";
import { Carousel, Card } from "react-bootstrap";
import { MEDIA_URL } from "../../helpers/constants";


export default function AccessoryCarousal(props) {
  const photoArray = props.photos ? [...props.photos] : [];
  return (
    <Carousel>
        {
           photoArray.map(item=> (
               <Carousel.Item className="accessory-carousal-card" key={item.photo_id}>
                   <Card.Img src={`${MEDIA_URL}${item.file}`} variant="top" />
               </Carousel.Item>
           ))
        }
    </Carousel>
  );
}

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

export default function Catalogue() {
  return (
    <Container className="catalogue mt-5 mb-n5" fluid>
      <Row className="mt-10">
        <Container>
          <Row>
            <Col xs={12} className="mt-5 text-center">
              <div className="catalogueContent">
                <h1 className="">Get our Product Catalogue</h1>
                {/* <a
                  target="_blank"
                  href="FalconCloak_Brochure.pdf"
                  class="mt-5 btn-transparent base-btn wow fadeInRightBig btn btn-primary"
                > */}
                <Button
                  variant="primary"
                  type="link"
                  href="FalconCloak_Brochure.pdf"
                  className="mt-5 btn-transparent base-btn"
                  target="_blank"
                >
                  Download
                </Button>
                {/* </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

import actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  finalCart: [],
};

export default (state = initialState, action) => {
  if (action.type === actionTypes.FETCH_CART_DETAILS) {
    const existingproducts = JSON.parse(localStorage.getItem("cart")) || [];
    return {
      ...state,
      data: existingproducts,
    };
  } else if (action.type === actionTypes.ADD_PRODUCT_TO_CART) {
    const products = [...state.data];
    const existingItem = state.data.find(
      (item) => item.product_id === action.product.product_id
    );
    if (!existingItem) products.push(action.product);
    localStorage.setItem("cart", JSON.stringify(products));
    return {
      ...state,
      isLoading: false,
      data: products,
    };
  } else if (action.type === actionTypes.REMOVE_PRODUCT_FROM_CART) {
    const products = [];
    state.data.forEach((item) => {
      if (item.product_id !== action.product.product_id) products.push(item);
    });
    localStorage.setItem("cart", JSON.stringify(products));
    return {
      ...state,
      isLoading: false,
      data: products,
    };
  } else if (action.type === actionTypes.SET_FINAL_CHECKOUT) {
    localStorage.setItem("finalCart", JSON.stringify(action.products));
    return {
      ...state,
      finalCart: action.products,
    };
  }

  else if (action.type === actionTypes.GET_FINAL_CHECKOUT) {
    return {
      ...state,
      finalCart: JSON.parse(localStorage.getItem("finalCart" )) || [],
    };
  }
  return state;
};

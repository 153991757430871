import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardMedia,

} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AccessoryCarousal from "./AccessoryCarousal";
// import EnquiryForm from "../../containers/ProductDetails/EnquiryForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    "&:hover": {
      textDecoration: "none",
      color: "initial",
    },
    flex: 1,
  },
  mainText: {
    fontSize: theme.typography.pxToRem(24),
    textDecoration: "none",
    textShadow: "2px 2px blue",
  },
  heading: {
    fontSize: theme.typography.pxToRem(40),
    color: "white",
    textTransform: "uppercase",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "bold",
    letterSpacing: 2,
  },
  headingContainer: {
    padding: 15,
    backgroundColor: "black",
    marginTop: "-2px",
  },
}));

export default function ProductCard(props) {
  const [showModal, setShowModal] = useState(false);
  const { accessory } = props;
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardMedia>
          <AccessoryCarousal {...props} photos={accessory.photos} />
        </CardMedia>
        <Row className={classes.headingContainer}>
          <Col xs={12} sm={12} style={{}}>
            <h2 className={classes.heading}>{accessory.name}</h2>
          </Col>
          <Col xs={12} sm={12}>
            <Button
              variant="primary"
              className="mt-2 btn-transparent base-btn"
              // color="primary"
              component={Link}
              to={`products/${accessory.product_id}`}
            >
              Show more Details
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}

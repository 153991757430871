import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import Catalogue from "../../components/Home/Catalogue/Catalogue";
import { API_URL } from "../../helpers/constants";
import AccessoryCard from "../../components/Accessories/AccessoryCard";

export default function Accessory() {
  const [isLoading, setIsLoading] = useState(false);
  const [accessoryArray, setaccessoryArray] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URL}accessories`)
      .then(res => res.json())
      .then(res => {
        setaccessoryArray(res);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        alert("Something went wrong please try again after sometime");
      });
  }, []);
  return (
    <>
      <Container fluid className="productlist-hero headerHero">
        <h1 className="wow fadeInUpBig">Accessories</h1>
      </Container>
      <Container>
        <hr />
        <Row>
          {!!isLoading ? (
            <Col className="mt-10 text-center" xs={12}>
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </Col>
          ) : (
            accessoryArray.map(item => {
              if (item.photos.length) {
                return (
                  <Col xs={12} md={4} className="mt-5" key={item.product_id}>
                    <AccessoryCard photos={item.photos} {...item} />
                  </Col>
                );
              } else {
                  return ''
              }
            })
          )}
        </Row>
      </Container>
      <Catalogue />
    </>
  );
}

import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer";

export default function Shipping() {
  return (
    <>
      <Container fluid className="privacyPolicyHero headerHero">
        <h1 className="wow fadeInUpBig">Shipping Policy</h1>
      </Container>
      <Container fluid className="text-center mt-5"></Container>
      <Container>
        <h2>
          <strong>Shipping Policy</strong>
        </h2>

        
          <span class="stabilisation"></span>
         
          <p>
            Last updated date:{" "}
            <span id="span_id_last_update">November 30, 2023</span>
          </p>
          <p>
            <br />
            <strong>1. GENERAL</strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. This website with the URL of{" "}
            <span id="span_id_website_url">https://falconcloak.in</span>{" "}
            ("Website/Site") is operated by{" "}
            <span id="span_id_owner_w">Falcon Cloak</span> (
            <strong>"We/Our/Us"</strong>).
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. We are committed to delivering the order placed by You within the
            given period and in accordance with this Delivery Policy (
            <strong>"Policy"</strong>).
          </p>
          <p style={{paddingLeft: "30px"}}>
            c. Currently, We do deliver the products across India subject to the
            provisions of this Policy.
          </p>
          <p style={{paddingLeft: "30px"}}>
            d. You are advised to read Our Terms and Conditions along with this
            Policy at the following link:{" "}
            <span id="span_id_terms_link">
              https://www.falconcloak.in/terms-and-conditions
            </span>
            .
          </p>
          <p style={{paddingLeft: "30px"}}>
            e. By using this website, You agree to be bound by the terms
            contained in this Policy without modification. If You do not agree
            to the terms contained in this Policy, You are advised not to
            transact on this website.
          </p>
          <p style={{paddingLeft: "30px"}}>
            f. The following currency is used on this Website:{" "}
            <span id="span_id_price">Indian Rupees</span>. Your payment provided
            may be charged in your local currency at the applicable exchange
            rates.
          </p>
          <p style={{paddingLeft: "30px"}}>
            g. All orders are subject to product availability. If an item is not
            in stock at the time you place your order, We will notify you and
            refund the total amount using the original method of payment.
          </p>
          <p style={{paddingLeft: "30px"}}>
            h. We make all commercially reasonable endeavours to ensure that the
            products are delivered to you in a timely manner.
          </p>
          <p style={{paddingLeft: "30px"}}>
            i. Please read this Policy before making a purchase, so that You
            understand Your rights as well as what You can expect from Us.
          </p>
          <p>
            <strong>
              <br />
              2. DEFINITIONS
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. <strong>"Customer"</strong> means a person who buys any goods for
            consideration but does not include a person who purchases such goods
            with the purpose of reselling them.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. <strong>"Website"</strong> - means this website with the URL:{" "}
            <span id="span_id_website_url">https://falconcloak.in</span>.
          </p>
          <p>
            <strong>
              <br />
              3. COST OF SHIPPING
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. Shipping and handling rates may vary based on product, packaging,
            size, volume, type, and other considerations. The cost of shipment
            will be calculated at the check-out window.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. The aforementioned cost of shipping is applicable for delivery of
            products under this Website regardless of the value and delivery
            destination.
          </p>
          <p>
            <strong>
              <br />
              4. HANDLING TIME
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. It usually takes <span id="span_id_handling">10</span> (ten) days
            to process and dispatch the products once the order is placed with
            us.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. We strive to process and dispatch your products at the earliest.
          </p>
          <p>
            <strong>
              <br />
              5. TIME FOR SHIPPING
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. Delivery time depends on the product ordered and the location of
            delivery. Approximate delivery is specified on the product details
            or will be shown at the check-out window.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. The delivery time is indicative and can vary due to external
            factors.{" "}
          </p>
          <p>
            <strong>
              <br />
              6. INTERNATIONAL SHIPPING
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. Currently, We do not deliver to locations outside India.
          </p>
          <p>
            <strong>
              <br />
              7. OTHER RULES
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. The shipping address cannot be changed once the order has been
            placed on the Website.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. While We shall strive to ship all your ordered products together,
            this may not always be possible due to product characteristics, or
            availability.{" "}
          </p>
          <p style={{paddingLeft: "30px"}}>
            c. Partial refunds will not be entertained.
          </p>
          <p style={{paddingLeft: "30px"}}>
            d. We endeavour to engage logistic partners, employees, agents with
            the highest standards and ethics, you agree and acknowledge that the
            actions, inactions of the delivery individuals are not in our
            control, and it is not possible for us to monitor and observe each
            delivery executive and We shall not be liable for any action or
            inaction from the part of logistic partners. Any disputes between
            You and logistics partners shall be settled between you and the
            logistics partner.{" "}
          </p>
          <p>
            <strong>
              <br />
              8. RESTRICTIONS
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. Some items may not be eligible to be delivered to all
            geographical locations. You will be notified of the restricted items
            at the check-out page or before that.
          </p>
         
          <p style={{paddingLeft: "30px"}}>
            <span class="flou ">
              <span id="span_id_8">
                <span class="variable_vide">________</span>
              </span>
              . 52 252 25525 5228 222 2522 82 22 828 52828252822 525 88 5285225
              52 252 8255825 222882, 252 858222258 552 52822288882 225
              8222582822 252 8255825 8258882 25288525 822252 52252585822 58.
            </span>
          </p>
          <p>
            <br />
            <strong>9. WRONG OR INCOMPLETE ADDRESS</strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. You shall ensure that all information that is submitted by You to
            us on the platform is true, complete, accurate and sufficient to
            identify the actual place of delivery.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. If You have entered the wrong shipping information or contact
            details, it might cause a delay or missed delivery and you will not
            be eligible for a reshipment or a refund on such transaction.
          </p>
          <p>
            <strong>
              <br />
              10. RETURN AND REFUND
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. Every effort is made so as to service the orders placed, as per
            the specifications and timelines mentioned with respect to a
            product. If due to any unforeseen circumstances or limitations from
            Our side, the order is not shipped or delivered then such order
            stands cancelled, and the amount paid by You is refunded.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. You can cancel the order after the Date of Transaction and prior
            to the dispatch of the product. The order cannot be cancelled once
            the dispatch has been initiated.
          </p>
          <p style={{paddingLeft: "30px"}}>
            c. We will not process a return if You have placed the order for the
            wrong product model, colour, or incorrect product or service.{" "}
          </p>
          <p style={{paddingLeft: "30px"}}>
            d. When you make a qualifying return, We will refund the full
            amount, less the original shipping and handling charges.
          </p>
          <p style={{paddingLeft: "30px"}}>
            e. Return or refund shall only be considered once the Customer
            concerned produces relevant documents and proof.
          </p>
          <p style={{paddingLeft: "30px"}}>
            f. Return and refund shall be subject to testing and verifying the
            alleged fault by an authorized person on behalf of Us.{" "}
          </p>
          <p style={{paddingLeft: "30px"}}>
            g. If following the testing process, the product is found to be in
            good working order without defects, We will return the product to
            you, and the carriage costs of this return will be Your
            responsibility.
          </p>
          <p style={{paddingLeft: "30px"}}>
            h. The product will be eligible for replacement only if the same
            product/model is available in stock. In case the same product/model
            is out of stock, a refund shall be provided.
          </p>
          <p style={{paddingLeft: "30px"}}>
            i. Once qualified, the refunds are applied to the original payment
            option.
          </p>
          <p style={{paddingLeft: "30px"}}>
            j. Please be informed that the products purchased using the Cash on
            Delivery option will be refunded to the Bank account with the
            details provided by you to Us.
          </p>
          <p style={{paddingLeft: "30px"}}>
            k. Customers who wish to make a warranty claim must comply with the
            manufacturer's instructions and warranty procedure. If the details
            of the product manufacturer are not available, we will help you with
            such details to directly connect with the manufacturer to resolve
            your issues.
          </p>
          <p style={{paddingLeft: "30px"}}>
            l. Replacement can be made if the Customer establishes that the
            product was delivered in defective condition or has physical damage
            within <span id="span_id_hours_inform">48</span> (forty-eight) hours
            of receipt of the product.
          </p>
          <p style={{paddingLeft: "30px"}}>
            m. We will organize to repair the damaged product or collect it and
            replace it with an equivalent product, or provide a refund, provided
            that You contact us within <span id="span_id_hours_inform">48</span>{" "}
            (forty-eight) hours of receipt of the product.
          </p>
          <p style={{paddingLeft: "30px"}}>
            n. Any damaged product must be returned in the condition it was in
            when you received it, together with any packaging and other items
            which You received with the damaged product.
          </p>
          <p style={{paddingLeft: "30px"}}>
            o. If the amount has been deducted and the order is not confirmed,
            please do contact Your respective bank. It takes 7 (seven) Business
            Days to reverse back the amount by the respective bank.
          </p>
          <p style={{paddingLeft: "30px"}}>
            p. If the issue has not been resolved within 7 (seven) Business
            Days, you can contact Our customer care support as follows:{" "}
            <span id="span_id_customer_care">falconcloak.design@gmail.com</span>
            .
          </p>
          <p style={{paddingLeft: "30px"}}>
            q. Notwithstanding the other provisions of this Policy, We may
            refuse to provide repair, replacement, or refund for a product or
            service you purchased if:
          </p>
          <p style={{paddingLeft: "60px"}}>
            I. the product purchased is sale items or promotional items
            (discounts, giveaways, etc.).
          </p>
          <p style={{paddingLeft: "60px"}}>
            II. the products are not in the same condition as when they were
            delivered.
          </p>
          <p style={{paddingLeft: "60px"}}>
            III. misused the product in a way that caused the problem.
          </p>
          <p style={{paddingLeft: "60px"}}>
            IV. You knew or were made aware of the problem(s) with the product
            or service before you purchased it.
          </p>
          <p style={{paddingLeft: "60px"}}>V. Gifts or free services.</p>
          <p style={{paddingLeft: "60px"}}>
            VI. the product is broken, tampered with or damaged.
          </p>
          <p style={{paddingLeft: "60px"}}>
            VII. the product is returned after the return window is closed or
            the Refund Period expires.
          </p>
          <p style={{paddingLeft: "60px"}}>
            VIII. Any other exceptions apply under the Consumer Protection Act,
            2019.{" "}
          </p>
          <p>
            <br />
            <strong>11. RULES OF RETURNING THE PRODUCT</strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. The request for cancellation of the products can be made in the
            following manner:
          </p>
          <p style={{paddingLeft: "60px"}}>
            <span id="span_id_product_cancellation_method">
              You can send a mail to: falconcloak.design@gmail.com
            </span>
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. You are eligible to return the product only if You return the
            products in the same condition in which You received them.
          </p>
          <p style={{paddingLeft: "30px"}}>
            c. Pack the product properly and label the product with the order
            number, return address and Your address.{" "}
          </p>
          <p style={{paddingLeft: "30px"}}>
            d. We do not accept liability for packages damaged during the return
            transit. It is the customer's responsibility to pack the product
            properly to prevent any damage during transit.{" "}
          </p>
          <p style={{paddingLeft: "30px"}}>
            e. Upon receiving Your return request, We shall verify the request
            and if found genuine, We will arrange a pick-up of the product
            through an assigned logistics service provider.
          </p>
          <p style={{paddingLeft: "30px"}}>
            f. The order returned must accompany all accessories (including
            freebies) which were serviced along with the order or else the cost
            of the accessories shall be recovered from the refund amount.
          </p>
          <p style={{paddingLeft: "30px"}}>
            g. On receipt of the returned product, We will test it to identify
            the fault You have notified Us.
          </p>
          <p style={{paddingLeft: "30px"}}>
            h. A copy of the receipt or invoice shall be attached along with the
            returned product.{" "}
          </p>
          <p>
            <br />
            <strong>12. YOUR DATA</strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. The privacy of your data supplied to Us during the shipping
            procedure is also governed by our privacy policy, which can be
            accessed under the following link:{" "}
            <span id="span_id_link_to_a_privacy_policy" class="encours">
              https://www.falconcloak.in/privacy-policy
            </span>
            .
          </p>
          <p>
            <strong>
              <br />
              13. CHANGES TO THIS POLICY
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            a. Please note that We may from time to time change the terms of
            this Policy and every time You wish to use this website, please
            check the Policy to ensure You understand the terms and conditions
            that apply at that time.
          </p>
          <p style={{paddingLeft: "30px"}}>
            b. If you do not wish to accept the revised Policy, you should not
            continue to use the Services. If you continue to use the Services
            after the date on which the changes, come into effect, Your use of
            the Services indicates your agreement to be bound by the new Policy.
          </p>
          <p>
            <strong>
              <br />
              14. FORCE MAJEURE
            </strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            We shall not be considered in breach of guarantee or terms of
            service and shall not be liable to the Customer for any cessation,
            interruption, or delay in the performance of its obligations by
            reason beyond Our control including natural disasters, pandemics,
            fire, an act of God or public enemy, famine, plague, the action of
            the court or public authority, change in law, explosion, war,
            terrorism, armed conflict, labour strike, lockout, boycott or
            similar event beyond our reasonable control, whether foreseen or
            unforeseen.
          </p>
          <p>
            <br />
            <strong>15. CONTACT US</strong>
          </p>
          <p style={{paddingLeft: "30px"}}>
            For any feedback, concern, or query, You may please reach out to Us
            on the contact details below.
          </p>
          <p style={{paddingLeft: "30px"}}>
            Customer Care:{" "}
            <span id="span_id_customer_care">falconcloak.design@gmail.com</span>
          </p>{" "}
     
      </Container>
      <Footer />
    </>
  );
}

/* eslint-disable no-throw-literal */
import Auth from "../helpers/auth";
import store from "../store";

export const unauthorizeUser = () => {
  // Auth.clearToken();
  return {
    type: "RESET_USER",
  };
};

export const hideError = () => ({
  type: "HIDE_SNACK_ERROR",
});

const headers = new Headers({
  "Content-Type": "application/json",
});

export const makePostCall = (endPoint, data) => {
  return fetch(endPoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
};

export const makePutCall = (endPoint, data) => {
  return fetch(endPoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: headers,
  });
};

export const makeGetCall = (endPoint) => {
  return fetch(endPoint, {
    method: "GET",
    headers: headers,
  });
};

export const makeAuthenticatedPostCall = (endPoint, data) => {
  const token = Auth.getToken();
  if (!token) {
    store.dispatch(unauthorizeUser());
    return new Promise((resolve, reject) => {
      reject("UnauthenticatedUser");
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return fetch(endPoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
};

export const makeAuthenticatedPutCall = (endPoint, data) => {
  const token = Auth.getToken();
  if (!token) {
    store.dispatch(unauthorizeUser());
    return new Promise((resolve, reject) => {
      reject("UnauthenticatedUser");
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return fetch(endPoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: headers,
  });
};

export const makeAuthenticatedDeleteCall = (endPoint) => {
  const token = Auth.getToken();
  if (!token) {
    store.dispatch(unauthorizeUser());
    return new Promise((resolve, reject) => {
      reject("UnauthenticatedUser");
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return fetch(endPoint, {
    method: "DELETE",
    headers: headers,
  });
};

export const makeAuthenticatedGetCall = (endPoint) => {
  const token = Auth.getToken();
  if (!token) {
    store.dispatch(unauthorizeUser());
    return new Promise((resolve, reject) => {
      reject("UnauthenticatedUser");
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return fetch(endPoint, {
    method: "GET",
    headers: headers,
  });
};

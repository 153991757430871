import React, { useState, Fragment } from "react";
import { Row, Col, Image, Carousel } from "react-bootstrap";
import { MEDIA_URL } from '../../helpers/constants';

export default function ProductCarousel(props) {
  const [defaultPhoto, setDefaultPhoto] = useState(props.photos[0]);
  return (
      <Fragment>
    <Row className="productCarousel carouselDisplay">
      <Col md={9} className="d-none d-sm-block">
        <Image src={`${MEDIA_URL}${defaultPhoto.file}`} fluid  className="product-base-image"/>
      </Col>
      <Col md={3} className="d-none d-sm-block">
        {props.photos.map(photo => (
          <Image
            key={photo.photo_id}
            src={`${MEDIA_URL}${photo.file}`}
            onClick={() => setDefaultPhoto(photo)}
            className={photo.photo_id === defaultPhoto.photo_id ? 'selectedProductImage miniaturePhoto': 'miniaturePhoto'}
            fluid
          />
        ))}
      </Col>
    </Row>
    <Row>
        <Col xs={12} className="d-block d-sm-none">
            <Carousel>
            {props.photos.map(photo => (
            <Carousel.Item key={photo.photo_id}>
                <Image src={`${MEDIA_URL}${photo.file}`} fluid/>
            </Carousel.Item>))
            }
            </Carousel>
        </Col>
    </Row>
    </Fragment>
  );
}

import React from "react";
import { Container, Image, Col, Row, ResponsiveEmbed } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import Divider from "@material-ui/core/Divider";
import ThreeD from "../../assets/images/3d.jpg";
import Prototype from "../../assets/images/prototype.jpg";
import Sketching from "../../assets/images/sketching.jpg";

import Catalogue from "../../components/Home/Catalogue/Catalogue";
import Footer from "../../components/Footer/Footer";

export default function Process() {
  // useEffect(() => {
  //   const wow = new WOW({live: false}); // disables sync requirement
  //   wow.init()
  //   $(window).on('scroll', function() { // jQuery to check for page scroll, you can replace this with an equivalent if you don't like using both jQuery and ReactJS at the same time

  //     wow.sync(); // WOW.js initializes only once the page has scrolled

  // })
  // }, []);
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <>
      <Container fluid className="d-none d-sm-block process-hero-vid">
        <Row>
          <div className="video-overlay">
            {" "}
            <h1>Process </h1>{" "}
          </div>
          <video
            preload="auto"
            muted
            autoPlay
            poster={require("../../assets/images/process.jpg")}
            width="100%"
            height="auto"
            loop
          >
            <source
              src="https://nest.falconcloak.in/media/process_header.webm"
              type="video/webm"
            />
          </video>
        </Row>
      </Container>
      <Container fluid className="headerHero processHero d-sm-none">
        <h1>Process</h1>
      </Container>
      <Container className="mt-5 d-block d-sm-none">
        <Row>
          <Col>
            <div style={{ width: "100%", height: "auto" }}>
              <ResponsiveEmbed aspectRatio="16by9">
                <video
                  preload="auto"
                  muted
                  poster={require("../../assets/images/process.jpg")}
                  width="100%"
                  height="auto"
                  controls
                >
                  <source
                    src="https://drive.google.com/uc?export=download&id=1NJMvzoCnXY18HD31QhNnihnuZyq0OeDH"
                    type="video/webm"
                  />
                </video>
              </ResponsiveEmbed>
            </div>
          </Col>
        </Row>
      </Container>
      
      <Container className="text-left mt-5">
        <Row>
          <Col xs={12} md={6} className="action-section fadeInLeft ">
            <Image src={Sketching} fluid />
          </Col>
          <Col xs={12} md={6} className="mt-3 mt-sm-0 text-center text-sm-left">
            <animated.div style={props}>
              <h1>
                <strong>Sketching</strong>
              </h1>
              <p className="text-justify">
                Every design starts from a sketch. 100s of sketches are laid out
                in the ideation stage before they are narrowed down to the final
                few concepts. Starting from orthographic front view to
                perspective view for the final designs, sketches are a great way
                to fabricate your ideas on paper.
              </p>
            </animated.div>
          </Col>
        </Row>
      </Container>
      <Divider variant="middle" className="mt-sm-5 mt-0" />

      <Container className="text-left mt-5">
        <Row className="flex-column-reverse flex-md-row ">
          <Col
            xs={12}
            md={6}
            className="text-left fadeInLeft mt-3 mt-sm-0 text-center text-sm-left"
          >
            <h1>
              <strong> 3D Modelling </strong>
            </h1>

            <p className="text-justify">
              Once the design is being finalised on paper the next step is to
              take to 3d, CAD models are made of these designs to have a better
              and complete view of the design. Visualisation is done on the
              rendering software of the final 3d models.
            </p>
          </Col>
          <Col xs={12} md={6} className="action-section fadeInRight">
            <Image src={ThreeD} fluid />
          </Col>
        </Row>
      </Container>
      <Divider variant="middle" className="mt-sm-5 mt-0" />
      <Container className="text-left mt-5">
        <Row>
          <Col xs={12} md={6} className="action-section  fadeInLeft">
            <Image src={Prototype} fluid />
          </Col>
          <Col
            xs={12}
            md={6}
            className="fadeInRight mt-3 mt-sm-0 text-center text-sm-left"
          >
            <h1>
              <strong>Prototype</strong>
            </h1>
            <p className="text-justify">
              It is the penultimate stage of development where before making the
              production model, prototypes are made to see whether or not design
              translates from paper to reality, iterations and changes are made
              until we are happy with the final outcome.
            </p>
          </Col>
        </Row>
      </Container>
      <Catalogue />
      <Footer />
    </>
  );
}

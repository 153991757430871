import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Catalogue from "../Home/Catalogue/Catalogue";
import { API_URL } from "../../helpers/constants";
// import ProductListItem from "./ProductListItem";
import Footer from "../Footer";
import ProductListItemV2 from "./ListItemV2";
// import ProductCard from "./ProductCard";
import AccessoryCard from "../Accessories/AccessoryCard";

const fetchProducts = () => {
  return fetch(`${API_URL}v2/product/list`)
    .then((res) => res.json())
    .then((res) => res.response);
};

export default function ProductList() {
  const { isLoading, data, error } = useQuery("productList", fetchProducts);
  if (error) alert("Something went wrong please try again");
  const accessoriesList = data?.filter((item) => item.is_accessory) || [];
  const productArray = data?.filter((item) => !item.is_accessory) || [];

  return (
    <>
      {/* <Container fluid className=" d-none d-sm-block  process-hero-vid">
        <Row>
          <div className="video-overlay">
            {" "}
            <h1>Our Products</h1>{" "}
          </div>
          <video
            preload="auto"
            muted
            autoPlay
            poster={require("../../assets/images/process.jpg")}
            width="100%"
            height="100%"
            loop
          >
            <source
              src="https://nest.falconcloak.in/media/product_header.webm"
              type="video/webm"
            />
            <source
              src="https://nest.falconcloak.in/media/product_header.mp4"
              type="video/mp4"
            />
          </video>
        </Row>
      </Container> */}
      <Container fluid className="headerHero productlist-hero ">
        <h1>OUR PRODUCTS</h1>
      </Container>
      <Container className="text-center mt-5 productlist mainProductList">
        <hr />
        <Row>
          {!!isLoading ? (
            <Col className="mt-10 text-center" xs={12}>
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </Col>
          ) : (
            productArray.map((item) => {
              return (
                <Col xs={12} md={12} className="mt-5" key={item.product_id}>
                  {/* <Product key={item}  {...item} isFeatured={false} /> */}
                  {/* <ProductCard key={item} {...item}/> */}
                  {/* <ProductListItem product={item} key={item.product_id} /> */}
                  <ProductListItemV2 product={item} key={item.product_id} />
                </Col>
              );
            })
          )}
          <Col xs={12} md={4} className="mt-5"></Col>
        </Row>
        {!!accessoriesList.length && (
          <>
            <Row>
              <Col xs={12} className="text-center mb-5">
                <h2>Accessories</h2>
              </Col>
            </Row>
            <Row>
              {accessoriesList.map((item) => {
                return (
                  <Col xs={12} sm={6} md={6} key={item.product_id}>
                    <AccessoryCard accessory={item} />
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </Container>
      <Catalogue />
      <Footer />
    </>
  );
}

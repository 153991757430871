import React, { Fragment } from "react";
import { Container, Image, Col, Row } from "react-bootstrap";
import Catalogue from "../../components/Home/Catalogue/Catalogue";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import HomeImage1 from "../../assets/images/ab2.webp";
import HomeImage2 from "../../assets/images/ab1.webp";
import WhatWeDoImage from "../../assets/images/things-we-do.webp";

export default function AboutUs() {
  return (
    <Fragment>
      <Helmet>
        <meta name="title" content="About Falcon Cloak" />
      </Helmet>
      <Container fluid className="aboutusHero headerHero">
        <h1 className="wow fadeInUp">
          <strong>About Us</strong>
        </h1>
      </Container>
      <Container className="text-center mt-5">
        <hr />
        <h1 className="fadeInLeft">Falcon Cloak</h1>
        <h3>Beacon of design Androgogy</h3>
        <hr />
        <Row>
          <Col xs={12} sm={{ offset: 1, size: 10 }}>
            <Row>
              <Col
                xs={12}
                md={{ size: 6 }}
                className="offer-section fadeInLeft"
              >
                <Image src={HomeImage1} fluid />
              </Col>
              <Col xs={12} md={6} className="fadeInRight">
                {/* <h3>Falcon Cloak</h3> */}
                <p className="text-justify">
                  Welcome to Falcon Cloak, a distinguished furniture brand born
                  from the creative vision of an Industrial design graduate from
                  the esteemed International School of Design (ISD) in India.
                  Our mission is to craft a truly exceptional and unparalleled
                  design language within the realm of furniture. With a track
                  record of success, including accolades such as the renowned
                  "Best Interior Designer" awards, we pride ourselves on
                  delivering innovative and distinctive designs that redefine
                  the boundaries of artistic expression.
                </p>
				<p className="text-justify"> At Falcon Cloak, we understand that furniture is not merely a functional aspect of interior spaces; it is a statement of individuality and style. We believe in transcending the ordinary and infusing each piece with a unique character that resonates with discerning individuals who appreciate exceptional design.</p>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <div className="hr mt-5" />
          </Col>
        </Row>
      </Container>
      <Container className="text-center mt-5">
        <hr />

        <h1 className=" fadeInRight">Vijit Bagh</h1>
        <hr />
        <Row>
          <Col xs={12} sm={{ offset: 1, size: 10 }}>
            <Row>
              <Col xs={12} md={{ size: 6 }} className="fadeInLeft">
                <p className="text-justify">
				Our dedicated team of talented designers and craftsmen meticulously handcraft each furniture piece, ensuring the utmost attention to detail and quality. We blend traditional craftsmanship techniques with modern technology, resulting in timeless creations that stand out in any space
                </p>
                <p className="text-justify">
				From our sleek and contemporary collection to our bold and avant-garde designs, every Falcon Cloak creation is a testament to our unwavering commitment to craftsmanship, innovation, and artistic excellence. Our furniture pieces not only fulfill their intended purpose but also become focal points of admiration and conversation.
				</p>
				{/* <p className="text-justify">
				Experience the allure of Falcon Cloak, where design and innovation intertwine to redefine the concept of furniture.
				</p> */}
              </Col>
              <Col xs={12} md={6} className="offer-section fadeInRight">
                <Image src={HomeImage2} fluid />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <div className="hr mt-5" />
          </Col>
        </Row>
        {/* <Row className=" mt-5">
          <Col xs={12}>
            <h1 className=" fadeInRight">CAD</h1>
            <p className="text-center">
              We provide detailed 2D layout and 3D designs to our clients as a
              part of Interior and space design projects.
            </p>
            <Image src={CadImage} fluid />
            <div className="hr mt-5" />
          </Col>
        </Row> */}

        <Row className=" mt-5">
          <Col xs={12}>
            <h1 className=" fadeInRight">Things we also do</h1>
            <p className="text-center">
              Aside from Furniture we also make interior components like
              fibreglass wall panels, sculptures and etc .
            </p>
            <Image src={WhatWeDoImage} fluid />
          </Col>
        </Row>
      </Container>
      <Catalogue></Catalogue>
      <Footer />
    </Fragment>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Divider } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function DetailsAccordian({ details }) {
  const classes = useStyles();

  return (
    <div className="d-block d-sm-none">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{details.other_details}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Specifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row>
            <Col xs={12}>
              <h5 className="d-flex justify-content-center">
                <strong>Colors Available</strong>
              </h5>
              <div className="d-flex justify-content-center">
                {details.colors_available.map((item) => item)}
              </div>
              <Divider />
            </Col>

            <Col xs={12}>
              <h5 className="d-flex justify-content-center">
                <strong>Dimensions</strong>{" "}
              </h5>
              <div className="d-flex justify-content-center">
                {details.dimensions}
              </div>
              <Divider />
            </Col>

            <Col xs={12}>
              <h5 className="d-flex justify-content-center">
                <strong>Materials</strong>{" "}
              </h5>
              <div className="d-flex justify-content-center">
                {details.material}
              </div>
            </Col>
          </Row>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            Delivery Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Standard Product delivery time is 4-6 weeks from the payment
            confirmation date.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

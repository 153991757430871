import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Row, Col } from "react-bootstrap";
// import TabPanel from '@material-ui/core/Tab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`a11y-tabpanel-${index}`}
      aria-labelledby={`a11y-tab-${index}`}
      {...other}
      className="mt-5"
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function CenteredTabs(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { details} = props;

  return (
    <div className="d-none d-sm-block">
      <div className="d-flex justify-content-center" style={{ borderBottom: '1px solid #ddd'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Description" value={0} {...a11yProps(0)} />
          <Tab label="Specifications" value={1} {...a11yProps(1)} />
          <Tab label="Delivery Information" value={2} {...a11yProps(2)} />
        </Tabs>
      </div>
      <div>
        <TabPanel value={value} index={0}>
          <p>{details.other_details}</p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Row className="wow fadeInRight">
            <Col md={4} className="d-flex justify-content-center border-right mb-2">
              <div>
                <h4>
                  <strong>Colors Available</strong>
                </h4>
                <div className="d-flex justify-content-center">
                  {details.colors_available.map((item) => item)}
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex justify-content-center border-right mb-2">
              <div>
                <h4>
                  <strong>Dimensions</strong>{" "}
                </h4>
                <div className="d-flex justify-content-center">
                  {details.dimensions}
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex justify-content-center">
              <div>
                <h4>
                  <strong>Materials</strong>{" "}
                </h4>
                <div className="d-flex justify-content-center">
                  {details.material}
                </div>
              </div>
            </Col>
          </Row>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Standard Product delivery time is 4-6 weeks from the payment confirmation date.
        </TabPanel>
      </div>
    </div>
  );
}

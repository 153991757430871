import React, { useState } from "react";
import { Container, Row, Col, Spinner, Image } from "react-bootstrap";
import { Button, Snackbar, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CloseIcon from "@material-ui/icons/Close";
import { API_URL } from "../../helpers/constants";
import ProductCarousel from "./ProductCarousel";
import DataTabs from "../../components/ProductList/Product/DataTabs";
import DetailsAccordian from "../../components/ProductList/Product/DetailsAccordian";
import { MEDIA_URL } from "../../helpers/constants";
import Footer from "../../components/Footer";
import { addProductToCart } from "../../actions/cart";
import { convertToINR } from "../../helpers/formatters";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const fetchProductDetails = ({ queryKey }) => {
  const [, id] = queryKey;
  return fetch(`${API_URL}v2/product/${id}`)
    .then((res) => res.json())
    .then((res) => res.response || res.message);
};

const ProductDetails = (props) => {
  const { id } = useParams();
  if (!id) {
    props.history.push("/products");
  }
  const {
    isLoading,
    error,
    data: productDetails,
  } = useQuery(["productDetails", id], fetchProductDetails);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  if (error) {
    props.history.push("/products");
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleAddtoCart = (details) => {
    props.addToCart(details);
    setSnackBarOpen(true);
  };

  const details = productDetails;
  const shareUrl = `https://falconcloak.in/products/${details?.product_id}`;
  console.log("Props", shareUrl);

  if (isLoading && !details) {
    return (
      <Container className="mt-5 minHeight-500">
        <div className="mt-10 text-center">
          <Spinner animation="grow" />
          <Spinner animation="grow" />
          <Spinner animation="grow" />
        </div>
      </Container>
    );
  }
  return (
    <>
    <Helmet>
        <title>Falcon : {details.name}</title>
        <meta name="description" content={details.other_details} />
        <meta name="title" content={details.name} />
        <meta property="og:title" content={details.name} />
        <meta property="og:description" content={details.other_details} />
        <meta property="og:site_name" content="Falconcloak" />
        <meta property="og:image" content={`${MEDIA_URL}${details.photos?.[0].file}`} />
    </Helmet>
      <Container className="mt-10 minHeight-500">
        <Row className="mt-5">
          <Col xs={12} md={8} className="wow fadeInLeftBig">
            <h1 style={{ fontSize: "60px",
           textTransform: "uppercase",
           fontFamily:  "'Roboto', sans-serif",
           fontWeight: "bold",
           letterSpacing: 2
          }}>
              <strong>{details.name}</strong>
            </h1>
            {/* {details.status === "LISTED" && (
								<h5>
									{" "}
									<span
										style={{ textDecoration: "line-through", color: "red" }}
									>
										Regular Price: {convertToINR(details.before_discount_price)}
									</span>{" "}
									<br />
									Special Price: {convertToINR(details.price)}
								</h5>
							)} */}
          </Col>
          <Col className="mt-5 d-none d-sm-block" md={4}>
            {/* {details.status === "LISTED" && (
								<Button
									onClick={() => {
										handleAddtoCart(details);
									}}
									fullWidth
									variant="contained"
									color="secondary"
									size="large"
								>
									Add to Cart
								</Button>
							)} */}
          </Col>
          <Col xs={12} className="wow fadeInRightBig">
            {details?.photos?.length ? (
              <ProductCarousel
                photos={details.photos.filter((item) => !item.is_featured)}
              />
            ) : null}
          </Col>
        </Row>
        <Col className="mt-3 d-sm-none" xs={12}>
          {/* {details.status === "LISTED" && (
							<Button
								onClick={() => {
									handleAddtoCart(details);
								}}
								fullWidth
								variant="contained"
								color="secondary"
								size="large"
							>
								Add to Cart
							</Button>
						)} */}
        </Col>

        <hr />
        <DataTabs details={details} />
        <hr />
        <DetailsAccordian details={details} />
        <Col className="m-auto text-center" xs={12}>
          <div className="margin-top-30">
            <FacebookShareButton
              quote={`Falcon Cloak - ${details.name}`}
              url={shareUrl}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              quote={`Falcon Cloak - ${details.name}`}
              url={shareUrl}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
              title={`Falcon Cloak - ${details.name}`}
              url={shareUrl}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton
              quote={`Falcon Cloak - ${details.name}`}
              url={shareUrl}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
        </Col>
      </Container>
      <hr />
	  {details.videos && (
        <Container>
          <Row>
            <Col md={12}>
              <h3>Product Video</h3>
            </Col>
            <Col xs={12} className="mt-5 mb-5">
			{details.videos ? (
            <video
              preload="auto"
              muted
              autoPlay={false}
              poster={`${MEDIA_URL}${details.photos[0].file}`}
              width="100%"
              height="100%"
              loop
              controls
            >
              {details.videos.map((item) => {
                if (item.video_format === "WEBM") {
                  return (
                    <source
                      src={`${MEDIA_URL}${item.file}`}
                      type="video/webm"
                    />
                  );
                }
                return (
                  <source src={`${MEDIA_URL}${item.file}`} type="video/mp4" />
                );
              })}
            </video>
          ) : null}
            </Col>
          </Row>
        </Container>
      )}
      <hr />

      {details.photos.find((item) => item.is_featured) && (
        <Container>
          <Row>
            <Col md={12}>
              <h3>Product Journey</h3>
            </Col>
            <Col xs={12} className="mt-5 mb-5">
              <Image
                fluid
                src={`${MEDIA_URL}${
                  details.photos.find((item) => item.is_featured)?.file
                }`}
              />
            </Col>
          </Row>
        </Container>
      )}
	  
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Product Added to Cart"
        action={
          <>
            <Button
              variant="contained"
              component={Link}
              color="secondary"
              to="/cart"
            >
              {" "}
              View Cart{" "}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Footer />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (product) => dispatch(addProductToCart(product)),
  };
}
export default withRouter(connect(null, mapDispatchToProps)(ProductDetails));

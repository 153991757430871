import React from "react";
import { Carousel, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, capitalize, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { MEDIA_URL } from "../../helpers/constants";
import { convertToINR } from "../../helpers/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      textDecoration: "none",
      color: "initial",
    },
  },
  mainText: {
    fontSize: theme.typography.pxToRem(24),
    textDecoration: "none",
    textShadow: "2px 2px blue",
  },
  heading: {
    fontSize: theme.typography.pxToRem(40),
    color: "white",
    textTransform: "uppercase",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "bold",
    letterSpacing: 2,
  },
  headingContainer: {
    padding: 15,
    backgroundColor: "black",
    marginTop: "-2px",
  },
}));

export default function ProductListItemV2(props) {
  const classes = useStyles();
  const { product } = props;

  return (
    <Paper elevation={4} square>
      {/* <Carousel
        className="hero-background mb-5"
        fade
        interval={5000}
        touch
        style={{
          width: "100%",
          height: "auto",
          background: "transparent !important",
        }}
      > */}
        {product.photos.map((photo) => (
          // <Carousel.Item key={photo.photo_id}>
          <div 
          className="img-hover-zoom"
          >
            <Image
              fluid
              src={`${MEDIA_URL}${photo.file}`}
              alt={product.name}
              className="img-hover-zoom"
            />
            </div>
            
          // </Carousel.Item>
        ))}
      {/* </Carousel> */}
      {/* <Row>
        <Col>
          {product.videos ? (
            <video
              preload="auto"
              muted
              autoPlay={false}
              poster={`${MEDIA_URL}${product.photos[0].file}`}
              width="100%"
              height="100%"
              loop
              controls
            >
              {product.videos.map((item) => {
                if (item.video_format === "WEBM") {
                  return (
                    <source
                      key={item.video_id}
                      src={`${MEDIA_URL}${item.file}`}
                      type="video/webm"
                    />
                  );
                }
                return (
                  <source
                    key={item.video_id}
                    src={`${MEDIA_URL}${item.file}`}
                    type="video/mp4"
                  />
                );
              })}
            </video>
          ) : null}
        </Col>
      </Row> */}
      {product.status === "LISTED" && (
        <Col>
          <Row className={classes.headingContainer}>
            <Col xs={12} sm={12} style={{}}>
              <h2 className={classes.heading}>{product.name}</h2>
            </Col>
            <Col xs={12} sm={12}>
              <Button
                variant="primary"
                className="mt-2 btn-transparent base-btn"
                // color="primary"
                component={Link}
                to={`products/${product.product_id}`}
              >
                Show more Details
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Paper>
  );
}

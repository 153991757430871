const Auth = {
    loggedIn () {
        return !!localStorage.token
    },

    setToken (token) {
        localStorage.token = token;
    },

    clearToken () {
        localStorage.token = '';
    },

    getToken () {
        return localStorage.token;
    }
}

export default Auth;

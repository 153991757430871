import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Image } from "react-bootstrap";

function PromotionModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Meet us Live at.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={6} sm={6}>
            <Image
              src={require("../../../assets/images/home/ace-exibition.jpeg")}
              height={400}
              className="img-fluid"
            />
          </Col>
          <Col xs={12} md={6} sm={6}>
            <h2
              style={{
                fontFamily: "Work Sans, sans-serif",
                fontWeight: "bold",
              }}
            >
              Asia's Largest Architecture,{"\n"} Building Materials and Design
              Exhibition
            </h2>
            <h3>Hall 1, Stall A13</h3>
            <p>
              <a
                href="https://goo.gl/maps/URoPX2qgsQUv1kPg8"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bombay Exibition Center, Nesco Goregaon East, Mumbai 400063
              </a>
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PromotionModal;

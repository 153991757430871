import actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INIT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_USER_SUCCESS:
      sessionStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isLoading: false,
        error: null,
        user: action.payload,
      };
    case actionTypes.FETCH_USER_ERROR:
      sessionStorage.removeItem("user");

      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.HIDE_SNACK_ERROR:
      return {
        ...state,
        error: null,
      };
    case actionTypes.RESET_USER:
      sessionStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

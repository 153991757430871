import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactUsForm from "../../components/ContactUs/ContactUsForm";
// import HomeImage1 from "../../assets/images/home-offer-1.jpg";
import Footer from "../../components/Footer/Footer";

export default function ContactUs() {
  return (
    <Fragment>
      <Container fluid className="contactusHero headerHero">
        <h1 className="wow fadeInUpBig">
          <strong>Contact Us</strong>
        </h1>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col
            xs={12}
            md={6}
            className="offer-section contact-us wow fadeInLeft mb-5"
          >
            {/* <Image src={HomeImage1} fluid/> */}
            <Row>
              <Col xs={12}>
                <h3>Our Address</h3>
              </Col>
              <Col xs={12} sm={3} className="padding-top-50">
                <i className="fas fa-map-marked-alt alignCenter" />
              </Col>
              <Col sm={8} className="padding-top-50">
                <div className="text-contact">
                  <strong>Address :</strong>
                  <br />
                  <a href="/#" className="text">
                    <h5>Falcon Cloak</h5>
                    Walbhat Rd, Cama Industrial Estate, Goregaon, Mumbai,
                    Maharashtra 400063
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="padding-top-50">
              <Col xs={12} sm={3}>
                <i className="fas fa-envelope alignCenter" />
              </Col>
              <Col sm={8}>
                <div className="text-contact">
                  <strong>Email :</strong>
                  <br />
                  <a
                    href="mailto:falconcloak.design@gmail.com"
                    className="text"
                  >
                    falconcloak.design@gmail.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="padding-top-50">
              <Col xs={12} sm={3}>
                <i className="fas fa-phone-square alignCenter" />
              </Col>
              <Col sm={8}>
                <div className="text-contact">
                  <strong>Number :</strong>
                  <br />
                  <a href="tel:+919619822419" className="text">
                    +91-9619822419
                  </a>
                  <br />
                  Monday - Friday (10 am to 6.30 pm)
                  <br />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="fadeInRight">
            <ContactUsForm />
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Row>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.8471564594693!2d72.85433119999999!3d19.158166599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7ed632f7771%3A0x8d0225d29b92693e!2sFalcon%20cloak!5e0!3m2!1sen!2sin!4v1667897521023!5m2!1sen!2sin"
            width="100%"
            height="550"
            frameBorder="0"
            style={{ border: 0, marginBottom: "-50px" }}
            allowFullScreen
            title="Falcon Cloak"
          />
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </Fragment>
  );
}

import actionTypes from "./actionTypes";
import { getUser } from "../services/user";

export function fetchUserInit(product) {
  return {
    type: actionTypes.FETCH_USER_INIT,
  };
}

export function fetchUserSuccess(payload) {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    payload,
  };
}

export function fetchUserError(error) {
  return {
    type: actionTypes.FETCH_USER_ERROR,
    error,
  };
}

export function logoutUser() {
  return { type: actionTypes.RESET_USER };
}

export const getUserDetails = () => async (dispatch) => {
  dispatch(fetchUserInit());
  const res = await getUser()
    .then((res) => res.json())
    .catch((err) => dispatch(fetchUserError(err)));
  if (res?.code === 200) {
    return dispatch(fetchUserSuccess(res.response));
  }
  return res;
};

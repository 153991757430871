import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { Image, Col, Row, Container, Spinner } from "react-bootstrap";
import { Button } from "@material-ui/core";
import "../../helpers/pagepiling";
import "../../assets/styles/pagepiling.css";
import Footer from "../../components/Footer/Footer";
import PromotionModal from "../../components/Home/PromotionModal/PromotionModal";

export default function Home() {
  //   const [showLoader, setShowLoader] = useState(true);
  //   useEffect(() => {
  //     setTimeout(() => setShowLoader(false), 1500);
  //   }, []);
  const showCondition = new Date("November 14, 2022 0:00:00") > new Date();
  useEffect(() => {
    $(document).ready(function () {
      const element = document.getElementById("pagepiling");
      if (element) {
        $("#pagepiling").pagepiling();
      }
    });
  }, []);
  const [modalShow, setModalShow] = useState(showCondition);

  return (
    <div
      style={{
        overflow: "hidden",
        margin: 0,
        padding: 0,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100vh",
      }}
    >
      {modalShow ? (
        <PromotionModal show={modalShow} onHide={() => setModalShow(false)} />
      ) : null}

      <Helmet>
        <meta
          name="description"
          content="Falcon cloak is a Mumbai based multi-disciplinary design studio."
        />
        <meta property="og:title" content="FalconCloak" />
        <meta
          property="og:description"
          content="Falcon cloak is a Mumbai based multi-disciplinary design studio."
        />
        <meta property="og:site_name" content="Falconcloak" />
        <meta
          property="og:image"
          content="https://www.falconcloak.in/static/media/logo.8d9ad413.png"
        />
      </Helmet>
      {/* {showLoader ? (
        <>
		<Col className="mt-10 text-center" xs={12}>
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </Col>
		</>
      ) : ( */}
      <div id="pagepiling">
        <div className="section home-piano">
          <Container fluid>
            <Row className="fullHeight d-none d-sm-flex">
              <Col md={8} className="mt-5">
                <Image
                  className="d-xs-block d-sm-none mb-5 fadeInLeft bottom"
                  src={require("../../assets/images/piano.webp")}
                  fluid
                />
                <Image
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                  className="d-none d-sm-block"
                  src={require("../../assets/images/piano.webp")}
                  fluid
                />
              </Col>

              <Col md={4}>
                <Container className="mt-5 mr-auto text-right" fluid>
                  <Row className="mt-5">
                    <Col>
                      <h1 className="productName">Crimson Flow</h1>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-right">
                      <p className="productDescription">
                        This is a contemporary piano with a unibody design made
                        out of fibreglass in grand piano sizing. Colours are
                        customisable according to customer needs.
                      </p>
                      <Button className="btn-transparent mt-3">
                        <a href="products" className="font-white">
                          Read More
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            {/* DeskTop END */}
            {/* Mobile */}
            <Row className="fullHeight d-flex d-sm-none">
              <Col md={4}>
                <Container className="mt-5 mr-auto text-left" fluid>
                  <Row className="mt-5">
                    <Col>
                      <h1 className="productName">Crimson Flow</h1>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-left">
                      <p className="productDescription">
                        This is a contemporary piano with a unibody design made
                        out of fibreglass in grand piano sizing. Colours are
                        customisable according to customer needs.
                      </p>
                      <Button className="btn-transparent mt-3">
                        <a href="products" className="font-white">
                          Read More
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={8} xs={12} className="mt-5">
                <Image
                  style={{
                    position: "absolute",
                    bottom: 80,
                    right: 0,
                  }}
                  className="d-xs-block d-sm-none mb-5 fadeInLeft bottom"
                  src={require("../../assets/images/piano.webp")}
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section home-fly-bridge">
          <Container fluid>
            <Row className="fullHeight">
              <Col md={4}>
                <Container className="mt-5 ml-auto" fluid>
                  <Row className="mt-5 ">
                    <Col>
                      <h1 className="font-white productName">FLYBRIDGE</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="productDescription">
                        Take inspiration from medium-sized sailing boats fly
                        bridge incorporates aesthetics of a yacht and
                        practicality of a furniture.
                      </p>
                      <Button className="btn-transparent mt-3">
                        <a href="products" className="font-white">
                          Read More
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={8} sm={12} className="mt-5 flex-end">
                <Image
                  style={{
                    position: "absolute",
                    bottom: 80,
                    right: 0,
                  }}
                  className="d-xs-block d-sm-none fadeInLeft bottom fullWidth"
                  src={require("../../assets/images/home/flybridge-sofa.webp")}
                  fluid
                />
                <Image
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  }}
                  className="d-none d-sm-block fullWidth"
                  src={require("../../assets/images/home/flybridge-sofa.webp")}
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section home-pebble">
          <Container fluid>
            <Row className="fullHeight d-none d-sm-flex">
              <Col md={8} className="mt-5">
                <Image
                  className="d-xs-block d-sm-none mb-5 fadeInLeft bottom"
                  src={require("../../assets/images/home/Layer3.png")}
                  fluid
                />
                <Image
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                  className="d-none d-sm-block"
                  src={require("../../assets/images/home/home-cruise.png")}
                  fluid
                />
              </Col>

              <Col md={4}>
                <Container className="mt-5 mr-auto text-right" fluid>
                  <Row className="mt-5">
                    <Col>
                      <h1 className="productName">CRUISE LINER</h1>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-right">
                      <p className="productDescription">
                        Perfect Centerpiece to complete your living room,
                        Elegant and Practical.
                      </p>
                      <Button className="btn-transparent mt-3">
                        <a href="products" className="font-white">
                          Read More
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            {/* DeskTop END */}
            {/* Mobile */}
            <Row className="fullHeight d-flex d-sm-none">
              <Col md={4}>
                <Container className="mt-5 mr-auto text-left" fluid>
                  <Row className="mt-5">
                    <Col>
                      <h1 className="productName">CRUISE LINER</h1>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-left">
                      <p className="productDescription">
                        Perfect Centerpiece to complete your living room,
                        Elegant and Practical.
                      </p>
                      <Button className="btn-transparent mt-3">
                        <a href="products" className="font-white">
                          Read More
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={8} className="mt-5">
                <Image
                  style={{
                    position: "absolute",
                    bottom: 80,
                    right: 0,
                  }}
                  className="d-xs-block d-sm-none mb-5 fadeInLeft bottom"
                  src={require("../../assets/images/home/cruise-mobile.png")}
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section home-cruise-liner">
          <Container fluid>
            <Row className="fullHeight">
              <Col md={4}>
                <Row className="mt-5 ">
                  <Col>
                    <h1 className="productName">NEON PEBBLE</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="productDescription">
                      This sofa has a fluid design with a concave seating
                      perfect for lounging and big enough for hosting guests.
                    </p>
                    <Button className="btn-transparent mt-3">
                      <a href="products" className="font-white">
                        Read More
                      </a>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={8} sm={12} className="mt-5">
                <Image
                  style={{
                    position: "absolute",
                    bottom: 80,
                    right: 0,
                  }}
                  className="d-xs-block d-sm-none fadeInLeft fullWidth"
                  src={require("../../assets/images/home/pebble-home.png")}
                  fluid
                />
                <Image
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  }}
                  className="d-none d-sm-block fullWidth"
                  src={require("../../assets/images/home/pebble-home.png")}
                  fluid
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section home-footer">
          <Footer style={{ minHeight: "100vh" }} />
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

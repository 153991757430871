import actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_LIST_INIT:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case actionTypes.FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload
      };
    case actionTypes.FETCH_PRODUCT_LIST_ERROR:
        return {
            ...state,
        isLoading: false,
        error: action.error,
        }
    case actionTypes.HIDE_SNACK_ERROR:
        return {
            ...state,
        error: null,
        }
    default:
      return state;
  }
};

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './reducers/root';
// console.log("env", process.env.NODE_ENV)

let middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  
  export default store;
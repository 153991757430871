import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Logo from "../../assets/images/logo.png";

export default function Footer({ style }) {
  return (
    <Container fluid className="footer mt-5" style={{ ...style }}>
      <Row>
        <Container className="mt-5">
          <Row>
            <Col md={6} xs={12}>
              <Row>
                <Col xs={2}>
                  <Image src={Logo} fluid />
                </Col>
                <Col xs={10}>
                  <h2>Falcon Cloak</h2>
                </Col>
              </Row>

              <p className="text-justify">
                Falcon cloak is a Mumbai based multi-disciplinary design studio.
                Our Aim is to break the traditional mould of products and to
                create something which is striking and imaginative. Our focus is
                to provide our customers with excellent design with attention to
                detail which is durable as well.
              </p>
            </Col>
            <Col md={6} xs={12}>
              <h3>Useful Links</h3>
              <Row>
                <Col md={6} xs={6}>
                  <a href="/">
                    <p>Home</p>
                  </a>
                  <a href="/about-us">
                    <p>About us</p>
                  </a>
                  <a href="/products">
                    <p>Products</p>
                  </a>
                  <a href="/shipping-policy">
                    <p>Shipping and Delivery Policy</p>
                  </a>
                  <a href="/refunds-policy">
                    <p>Refunds Policy</p>
                  </a>
                </Col>
                <Col md={6} xs={6}>
                  <a href="/contact-us">
                    <p>Contact Us</p>
                  </a>
                  <a href="/process">
                    <p>Process</p>
                  </a>
                  <a href="/privacy-policy">
                    <p>Privacy Policy</p>
                  </a>
                  <a href="/terms-and-conditions">
                    <p>Terms and Conditions</p>
                  </a>
                </Col>
                <Col xs="auto">
                  <p>Follow us on</p>
                  <a href="https://www.facebook.com/falconcloak" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon size={32} round />
                  </a>
                  <a href="https://www.twitter.com/falconcloak" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon size={32} round />
                  </a>
                  <WhatsappShareButton
                    title={`Falcon Cloak - Designer Studios`}
                    url={"https://www.falconcloak.in"}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>

                  <a href="https://www.linkedin.com/company/falcon-cloak-design" target="_blank" rel="noopener noreferrer">
                    <LinkedinIcon size={32} round />
                  </a>
                  <a href="https://www.instagram.com/falcon_cloak/" target="_blank" rel="noopener noreferrer">
                    <Image
                      roundedCircle
                      height={32}
                      width={32}
                      src={require("../../assets/images/768px-Instagram_icon.png")}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} sm={12} className="text copyright mb-3">
              © - Copyright Falcon Cloak&#8482; 2023. All right reserved.
            </Col>
            <Col>
            
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

import actionTypes from "./actionTypes";
// import { hideError } from "./common";

export function addProductToCart(product) {
  return {
    type: actionTypes.ADD_PRODUCT_TO_CART,
    product,
  };
}

export function fetchCartDetails() {
  return {
    type: actionTypes.FETCH_CART_DETAILS,
  };
}

export function deleteProductFromCart(product) {
  return {
    type: actionTypes.REMOVE_PRODUCT_FROM_CART,
    product,
  };
}

export function setFinalCheckout(products) {
  return {
    type: actionTypes.SET_FINAL_CHECKOUT,
    products,
  };
}

export const getFinalCheckout = () => async (dispatch) => {
  return dispatch({
    type: actionTypes.GET_FINAL_CHECKOUT,
  });
};

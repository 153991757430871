import { makePostCall, makeAuthenticatedGetCall, makeAuthenticatedPostCall } from "./common";
import { API_URL } from "../helpers/constants";

export const signupUser = (data) => {
  const dataObject = {
    email: data.email,
    password: data.password,
    full_name: data.full_name,
  };
  return makePostCall(`${API_URL}sign-up`, dataObject);
};

export const getUser = () => {
  return makeAuthenticatedGetCall(`${API_URL}me`);
};

export const login = (data) => {
  const dataObject = {
    username: data.email,
    password: data.password,
  };
  return makePostCall(`${API_URL}login`, dataObject);
};

export const updateProfile = (data) => {
  const body = {
    email: data.email,
    mobile_number: data.mobile_number,
    full_name: data.full_name,
  };
  return makeAuthenticatedPostCall(`${API_URL}user/update-profile`, body);
};


export const updatePassword = (data) => {
  const body = {
    old_password: data.old_password,
    new_password: data.new_password,
  };
  return makeAuthenticatedPostCall(`${API_URL}user/update-password`, body);
};



export const resetPasswordInit = (data) => {
  const body = {
    email: data.email,
  };
  return makePostCall(`${API_URL}user/reset-password-init`, body);
};


export const resetPassword = (data) => {
  const body = {
    password: data.password,
    reset_token: data.token
  };
  return makePostCall(`${API_URL}user/reset-password`, body);
};
import React from "react";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import "./assets/styles/base.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { isProd } from "./helpers/constants";


const queryClient = new QueryClient();
// const WOW = require('wowjs').WOW;

// window.wow = new WOW({
//     live: false
// });

// window.wow.init();
if (isProd === "production") {
  console.log = function () {};
	console.warn = function () {};
	console.error = function () {};
} 

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();

import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer";

export default function Refunds() {
  return (
    <>
      <Container fluid className="privacyPolicyHero headerHero">
        <h1 className="wow fadeInUpBig">Refunds Policy</h1>
      </Container>
      <Container fluid className="text-center mt-5"></Container>
      <Container>
        <h2>
          <strong>Refunds Policy</strong>
        </h2>
        <span class="stabilisation"></span>
        <p style={{ textAlign: "left" }}>
          Last updated date:{" "}
          <span id="span_id_last_update">November 30, 2023</span>
        </p>
        <p>
          <strong>
            <br />
            1. GENERAL
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. This website with the URL of{" "}
          <span id="span_id_website_url">https://falconcloak.in</span> (
          <strong>"Website/Site"</strong>) is operated by{" "}
          <span id="span_id_owner_w">Falcon Cloak</span> (
          <strong>"We/Our/Us"</strong>).{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. We are committed to providing Our customers with the highest
          quality <span id="span_id_type">Products</span>. However, on rare
          occasions, <span id="span_id_type">Products</span> may be found to be
          faulty or deficient. In such cases, We offer return and refund
          facilities in accordance with this Refund Policy (
          <strong>"Policy"</strong>).
        </p>
        <p style={{ paddingLeft: "30px" }}>
          c. You are advised to read Our Terms and Conditions along with this
          Policy.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          d. By using this website, You agree to be bound by the terms contained
          in this Policy without modification. If You do not agree to the terms
          contained in this Policy, You are advised not to transact on this
          website.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          e. We offer a <span id="span_id_refund_days">10</span> days refund
          Policy for the eligible <span id="span_id_type">Products</span>.{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          f. Please read this Policy before making a purchase on this Website,
          so that You understand Your rights as well as what You can expect from
          Us if You are not happy with Your purchase.
        </p>
        <p>
          <strong>
            <br />
            2. DEFINITIONS
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. <strong>"Business Days"</strong> - means a day that is not a
          Saturday, Sunday, public holiday, or bank holiday in India or in the
          state where our office is located.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. <strong>"Customer"</strong> - means a person who buys any goods or
          availing services for consideration but does not include a person who
          purchases such goods or avail services to resell them.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          c.<strong> "Date of Transaction"</strong> - means the date of purchase
          of any product or service, which includes the date of any renewal
          processed in accordance with the terms and conditions of the
          applicable product or service agreement.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          d. <strong>"Website"</strong> - means this website with the URL:{" "}
          <span id="span_id_website_url">https://falconcloak.in</span>.
        </p>
        <p>
          <strong>
            <br />
            3. REFUNDS RULES
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. Every effort is made so as to service the orders placed, as per the
          specifications and timelines mentioned with respect to a{" "}
          <span id="span_id_type">Products</span>. If due to any unforeseen
          circumstances or limitations from Our side, the order is not shipped
          or delivered then such order stands cancelled, and the amount paid by
          You is refunded.{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. You can cancel the order after the Date of Transaction and prior to
          the dispatch of the product. The order cannot be cancelled once the
          dispatch has been initiated.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          c. We will not process a return if You have placed the order for the
          wrong product model, colour, or incorrect product or service.{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          d. When you make a qualifying return, We will refund the full amount,
          less the original shipping and handling charges.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          e. Return or refund shall only be considered once the Customer
          concerned produces relevant documents and proof.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          f. Return and refund shall be subject to testing and verifying the
          alleged fault by an authorized person on behalf of Us.{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          g. If following the testing process, the product is found to be in
          good working order without defects, We will return the product to you,
          and the carriage costs of this return will be Your responsibility.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          h. The product will be eligible for replacement only if the same
          product/model is available in stock. In case the same product/model is
          out of stock, a refund shall be provided.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          i. Once qualified, the refunds are applied to the original payment
          option.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          j. Please be informed that the products purchased using the Cash on
          Delivery option will be refunded to the Bank account with the details
          provided by you to Us.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          k. Customers who wish to make a warranty claim must comply with the
          manufacturer's instructions and warranty procedure. If the details of
          the product manufacturer are not available, we will help you with such
          details to directly connect with the manufacturer to resolve your
          issues.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          l. Please note that the Digital Products are non-returnable unless
          such Digital Product is materially non-compliant with its
          specifications or the physical media on which it is supplied is
          defective.
        </p>
        <p>
          <strong>
            <br />
            4. PRODUCTS DAMAGED DURING THE TRANSIT
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. Replacement can be made if the Customer establishes that the
          product was delivered in defective condition or has physical damage
          within <span id="span_id_hours_inform">48</span> hours of receipt of
          the product.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. We will organize to repair the damaged product or collect it and
          replace it with an equivalent product, or provide a refund, provided
          that You contact us within <span id="span_id_hours_inform">48</span>{" "}
          hours of receipt of the product.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          c. Any damaged product must be returned in the condition it was in
          when you received it, together with any packaging and other items
          which You received with the damaged product.
        </p>
        <p>
          <strong>
            <br />
            5. ORDER NOT CONFIRMED BUT AMOUNT DEDUCTED
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. If the amount has been deducted and the order is not confirmed,
          please do contact Your respective bank. It takes 7 (seven) Business
          Days to reverse back the amount by the respective bank.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. If the issue has not been resolved within 7 (seven) Business Days,
          you can contact Our customer care support as follows:{" "}
          <span id="span_id_customer_care">falconcloak.design@gmail.com</span>.
        </p>
        <p>
          <strong>
            <br />
            6. EXEMPTIONS
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. Notwithstanding the other provisions of this Policy, We may refuse
          to provide repair, replacement, or refund for a product or service you
          purchased if:
        </p>
        <p style={{ paddingLeft: "60px" }}>
          I. the product purchased is sale items or promotional items
          (discounts, giveaways, etc.).
        </p>
        <p style={{ paddingLeft: "60px" }}>
          II. the products are not in the same condition as when they were
          delivered.
        </p>
        <p style={{ paddingLeft: "60px" }}>
          III. misused the product in a way that caused the problem.
        </p>
        <p style={{ paddingLeft: "60px" }}>
          IV. You knew or were made aware of the problem(s) with the product or
          service before you purchased it.
        </p>
        <p style={{ paddingLeft: "60px" }}>V. Gifts or free services.</p>
        <p style={{ paddingLeft: "60px" }}>
          VI. the product is broken, tampered or damaged.
        </p>
        <p style={{ paddingLeft: "60px" }}>
          VII. the product is returned after the return window is closed or the
          Refund Period expires.
        </p>
        <p style={{ paddingLeft: "60px" }}>
          VIII. Any other exceptions apply under the Consumer Protection Act,
          2019.{" "}
        </p>
        <p>
          <strong>
            <br />
            7. RULES OF RETURNING THE PRODUCT
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. The request for cancellation of the products can be made in the
          following manner:
        </p>
        <p style={{ paddingLeft: "60px" }}>
          <span id="span_id_product_cancellation_method">
            You can send an email to falconcloak.design@gmail.com
          </span>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. You are eligible to return the product only if You return the
          products in the same condition in which You received them.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          c. Pack the product properly and label the product with the order
          number, return address and Your address.{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          d. We do not accept liability for packages damaged during the return
          transit. It is the customer's responsibility to pack the product
          properly to prevent any damage during transit.{" "}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          e. Our technical support staff or customer support staff, as
          appropriate, will advise You of which method of delivery to use to
          return the products. Authorized product returns must be sent to the
          address communicated by Our authorized personnel.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          f. The order returned must accompany all accessories (including
          freebies) which were serviced along with the order or else the cost of
          the accessories shall be recovered from the refund amount.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          g. On receipt of the returned product, We will test it to identify the
          fault You have notified Us.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          h. A copy of the receipt or invoice shall be attached along with the
          returned product.{" "}
        </p>
        <p>
          <strong>
            <br />
            8. SHIPPING COST
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. We provide all support to return the product. However, any cost
          incurred on the packaging or returning of the product shall be borne
          by the customer itself.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. If the customer is sending back the product, the risk of loss lies
          with the customer. Proof of postage is not proof of delivery, and You
          are therefore strongly advised to send Your package by recorded
          delivery, registered post, or courier.
        </p>
        <p>
          <strong>
            <br />
            9. PRODUCTS CONTAINING YOUR DATA
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. In some cases, Your data may be stored in the device you are
          returning to us. It is advised to take a backup and remove all Your
          data before returning the product. We are not responsible for any loss
          or misuse of Your data.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. The privacy of your data supplied to Us during the return and
          refund procedure is also governed by our privacy policy.
        </p>
        <p>
          <strong>
            <br />
            10. RESPONSE TIME
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          a. Refunds are normally processed within{" "}
          <span id="span_id_refund">10</span> Business Days after the completion
          of quality checks of the product returned.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          b. Refunds are normally processed within{" "}
          <span id="span_id_refund">10</span> Business Days after checking the
          veracity of the refund request.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          c. The period of refund may also depend on various banking and payment
          channels, and We will not be liable for any errors or delays in a
          refund due to banks or third-party service providers.
        </p>
        <p>
          <strong>
            <br />
            11. CANCELLATION OF RETURN REQUEST
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          A request for return or refund once made can be cancelled by
          contacting customer care at{" "}
          <span id="span_id_customer_care">falconcloak.design@gmail.com</span>.
        </p>
        <p>
          <strong>
            <br />
            12. FORCE MAJEURE
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          We shall not be considered in breach of guarantee or terms of service
          and shall not be liable to the Customer for any cessation,
          interruption, or delay in the performance of its obligations by reason
          beyond Our control including natural disasters, pandemics, fire, an
          act of God or public enemy, famine, plague, the action of the court or
          public authority, change in law, explosion, war, terrorism, armed
          conflict, labour strike, lockout, boycott or similar event beyond our
          reasonable control, whether foreseen or unforeseen.
        </p>
        <p>
          <strong>
            <br />
            13. CONTACT US
          </strong>
        </p>
        <p style={{ paddingLeft: "30px" }}>
          For any feedback, concern, or query, You may please reach out to Us on
          the contact details below.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          Customer Care:{" "}
          <span id="span_id_customer_care">falconcloak.design@gmail.com</span>
        </p>{" "}
      </Container>
      <Footer />
    </>
  );
}

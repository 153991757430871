export default {
  FETCH_PRODUCT_LIST_INIT: "FETCH PRODUCT LIST INIT",
  FETCH_PRODUCT_LIST_SUCCESS: "FETCH PRODUCT LIST SUCCESS",
  FETCH_PRODUCT_LIST_ERROR: "FETCH PRODUCT LIST ERROR",

  FETCH_PRODUCT_DETAILS_INIT: "FETCH PRODUCT DETAILS INIT",
  FETCH_PRODUCT_DETAILS_SUCCESS: "FETCH PRODUCT DETAILS SUCCESS",
  FETCH_PRODUCT_DETAILS_ERROR: "FETCH PRODUCT DETAILS ERROR",

  HIDE_SNACK_ERROR: "HIDE SNACK ERROR",

  FETCH_CART_DETAILS: "FETCH CART DETAILS",
  ADD_PRODUCT_TO_CART: "ADD PRODUCT TO CART",
  REMOVE_PRODUCT_FROM_CART: "REMOVE PRODUCT FROM CART",

  SET_FINAL_CHECKOUT: "SET FINAL CHECKOUT",
  GET_FINAL_CHECKOUT: "GET FINAL CHECKOUT",

  FETCH_USER_INIT: "FETCH USER INIT",
  FETCH_USER_SUCCESS: "FETCH USER SUCCESS",
  FETCH_USER_ERROR: "FETCH USER ERROR",

  FETCH_SHIPPING_SUCCESS: "FETCH SHIPPING SUCCESS",
  FETCH_SHIPPING_ERROR: "FETCH SHIPPING ERROR",

  RESET_USER: "RESET USER"
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import NavBar from "../components/NavBar/NavBar";
import ContactUs from "./ContactUs/ContactUs";
import Home from "./Home/Home";
import AboutUs from "./AboutUs/AboutUs";
import ProductList from "../components/ProductList/ProductList";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import ProductDetails from "./ProductDetails/ProductDetails";
import WOW from "wowjs";
import Process from "./Process/Process";
import Accessory from "./Accessories/Accessory";
// import Cart from "./Cart";
// import Login from "./Login";
// import SignUp from "./SignUp";
// import Account from "./Account";
// import Checkout from "./Checkout/Checkout";

// import GuardedRoute from "../components/Auth/GuardedRoute";
// import Thankyou from "./Thankyou";
// import Orders from "./Order/Orders";
// import OrderDetails from "../components/Orders/OrderDetails";
// import PasswordReset from "./PasswordReset/PasswordReset";
// import PasswordResetInit from "./PasswordReset/PasswordResetInit";
// import NonGuardedRoute from "../components/Auth/NonGuardedRoute";
import { fetchCartDetails, getFinalCheckout } from "../actions/cart";
import { getUserDetails, fetchUserSuccess } from "../actions/user";
import Terms from "./PrivacyPolicy/Terms";
import Shipping from "./PrivacyPolicy/Shipping";
import Refunds from "./PrivacyPolicy/Refunds";
// import FirstVisitVideo from "./FirstVisitVideo";

class App extends Component {
  state = { scrolled: false, firstVisit: 0 };
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
    const { fetchCartDetails, user, fetchUser, setUser } = this.props;
    fetchCartDetails();
    document.addEventListener("scroll", () => {
      const backgroundcolor = window.scrollY < 100 ? "red" : "blue";

      this.setState({ navBackground: backgroundcolor });
    });

    const storageUser = JSON.parse(sessionStorage.getItem("user"));
    if (!user && !storageUser) {
      fetchUser();
    }
    if (storageUser) {
      setUser(storageUser);
    }

    //   const storage = parseInt(localStorage.getItem("firstVisit") || 0);
    //   let lastVisit = localStorage.getItem("lastVisit");
    //   let showVideo = false;
    // console.log(showVideo);
    //   if (lastVisit) {
    //     lastVisit = new Date(lastVisit);
    //     const today = new Date();
    //     const hours = Math.abs(today - lastVisit) / 36e5;
    //     showVideo = hours > 12;
    //   }
    // else {
    // 	showVideo = true;
    // }
    //   if ((!storage || storage < 3) && showVideo) {
    //     return this.setState({ firstVisit: true });
    //   }
  }
  // closeFirstVisit = () => {
  //   const count = (parseInt(localStorage.getItem("firstVisit")) || 0) + 1;
  //   localStorage.setItem("firstVisit", count);
  //   localStorage.setItem("lastVisit", new Date());
  //   this.setState({ firstVisit: false });
  // };

  render() {
    const { user } = this.props;

    return (
      <Router>
        <NavBar scrolled user={user} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route exact path="/products">
            <ProductList />
          </Route>
          <Route path="/products/:id">
            <ProductDetails />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/process">
            <Process />
          </Route>
          <Route path="/accessories">
            <Accessory />
          </Route>
          {/* <Route path="/cart">
            <Cart />
          </Route>
          <GuardedRoute path="/checkout" component={Checkout} user={user} /> */}
          {/* <NonGuardedRoute path="/login" exact component={Login} />
          <NonGuardedRoute path="/login/:redirectURL" component={Login} exact />
          <NonGuardedRoute path="/sign-up" component={SignUp} />
          <GuardedRoute path="/profile" component={Account} user={user} /> */}
          {/* <GuardedRoute path="/thanks" component={Thankyou} />
          <GuardedRoute path="/orders" component={Orders} exact />
          <GuardedRoute path="/orders/:orderId" component={OrderDetails} />
          <NonGuardedRoute
            path="/redirect/reset-password/:token"
            component={PasswordReset}
            exact
          />
          <NonGuardedRoute
            path="/reset-password"
            component={PasswordResetInit}
            exact
          /> */}
          <Route path="/terms-and-conditions">
            <Terms />
          </Route>
          <Route path="/shipping-policy">
            <Shipping />
          </Route>
          <Route path="/refunds-policy">
            <Refunds />
          </Route>
          <Route />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user?.user,
    userLoading: state.user?.isLoading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCartDetails: () => dispatch(fetchCartDetails()),
    fetchFinalCart: () => dispatch(getFinalCheckout()),
    fetchUser: () => dispatch(getUserDetails()),
    setUser: (user) => dispatch(fetchUserSuccess(user)),
  };
}
App = connect(mapStateToProps, mapDispatchToProps)(App);
export default App;

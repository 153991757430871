import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { Form, Alert } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";

import * as yup from "yup";
import { API_URL } from "../../helpers/constants";

const objectSchema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  email: yup
    .string()
    .email("Please provide a valid email address")
    .required("Please enter your email"),
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Please enter a valid 10 digit mobile number")
    .required("Please enter your mobile number"),
  message: yup.string().required("Please enter your message"),
  type: yup.string()
});

export default function ContactUsForm() {
  const [formSuccess, setFormSuccess] = useState();
  const sendContactUsQuery = (values, formActions) => {
    setFormSuccess(null);
    formActions.setSubmitting(true);
    console.log("v", values);
    console.log("a", formActions);
    const url = `${API_URL}contactusform`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values),
      method: "POST"
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        formActions.setSubmitting(false);
        setFormSuccess(true);
      })
      .catch(error => {
        formActions.setSubmitting(false);
        console.log(error);
        alert("Something went wrong, please try again after sometime");
        setFormSuccess(false);
      });
  };

  return (
    <div>
      <h4>
        For General enquiries please fill out the form, one of our executives
        will contact you soon
      </h4>
      {formSuccess ? (
        <Alert variant="warning">Details submitted successfully</Alert>
      ) : formSuccess === false ? (
        <Alert variant="warning">Error something went wrong</Alert>
      ) : (
        ""
      )}
      <Formik
        initialValues={{
          name: "",
          email: "",
          mobile: "",
          message: "",
          type: "GENERAL"
        }}
        validationSchema={objectSchema}
        onSubmit={sendContactUsQuery}
      >
        {props => (
          <Form className="form">
            <Form.Group>
              <TextField
                type="text"
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                label="Name*"
                fullWidth

              />
              <div className="form-error">
                <ErrorMessage name="name" />
              </div>
            </Form.Group>
            <Form.Group>
              <TextField
                type="email"
                name="email"
                value={props.values.email}
                onChange={props.handleChange}
                label="Email*"
                fullWidth

              />
              <div className="form-error">
                <ErrorMessage name="email" />
              </div>
            </Form.Group>
            <Form.Group>
              <TextField
                type="text"
                name="mobile"
                value={props.values.mobile}
                onChange={props.handleChange}
                label="Mobile Number*"
                fullWidth

              />
              <div className="form-error">
                <ErrorMessage name="mobile" />
              </div>
            </Form.Group>

            <Form.Group>
              <TextField
                as="textarea"
                fullWidth
                name="message"
                value={props.values.message}
                onChange={props.handleChange}
                label="Message*"
                multiline
                rowsMax={6}
              />
              <div className="form-error">
                <ErrorMessage name="message" />
              </div>
            </Form.Group>
            <Form.Group className="text-center">
              <Button
                variant="contained"
                color="primary"
                fullWidth

                type="button"
                disabled={props.isSubmitting}
                onClick={props.handleSubmit}
                className=""
              >
                Send
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
}
